import "./footer.scss";

function Footer() {

  return (
    <footer>
        <p>© 2023 Alithya</p>
    </footer>
  )
}

export default Footer