import './App.css';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { configureAxios } from './util/configure-axios';
import keycloak, { getKeycloak } from './keycloak/keycloak';
import { StrictMode, useEffect, useState } from 'react';
import Footer from './footer/footer';
import axios from 'axios';
import { Button, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {


  const onScale = async () => {
    try {
      await axios.get('/api/scale-up')
      checkState()
    } catch (e) {
    }
  }
  const [error, setError] = useState(null)
  const [canScale, setCanScale] = useState(true)
  const [secondsLeft, setSecondsLeft] = useState(0)

  const [timerSeconds, setTimerSeconds] = useState(-1)

  useEffect(() => {
    const timer = setTimeout(async () => {
      if (timerSeconds < 0) {
        setTimerSeconds(timerSeconds - 1)
        console.log('Timer on standby')
      } else if (timerSeconds == 0) {
        window.location.reload();
      } else {
        console.log('Do decrement')
        setTimerSeconds(timerSeconds - 1)
      }
    }, 1000)

    return () => {
      clearTimeout(timer);
    }

  }, [timerSeconds])
  

  const checkState = async() => {
    try {
      const state = (await axios.get('/api/status')).data
      console.log(state)

      if (state["state"] == "ScaleLayer") {
        setCanScale(false)
        const layersLeft = 3 -(state["layer_1"] + state["layer_2"] + state["layer_3"]) + 1
        setSecondsLeft(layersLeft*state["seconds_between_layers"] + 40)
        setTimerSeconds(layersLeft*state["seconds_between_layers"] + 40)
      } else {
        setCanScale(true)
      }
    } catch (e) {
      setError(`${e}`)
    }
  }

  useEffect(() => {
    checkState()
  }, [])

  return (
      <StrictMode>
        <Container>
          <div className='mt-4'>
          <h1 style={{textAlign: 'center'}}>Environment not active</h1>
          <h3>Status</h3>
          {error != null 
          ? <div>
              <p>The environment you are trying to access has been scaled down.</p>
              <p>While trying to fetch info an error has occured: {error}</p>
              <p>Please contact support, or wait a couple of minutes before refreshing the page.</p>
          </div>
          : <div>
            {canScale
              ? <div>
                <p>The environment you are trying to access has been scaled down.</p>
                <p>If you wish to scale it up, press the following button </p>
                <Button onClick={onScale}>Scale up</Button>
              </div>
              : <div>
                <p>The environment you are trying to access has been scaled down.</p>
                <p>However, it is already in the process of scaling up.</p>

                <p>The environment should be ready in less than <b>{secondsLeft} seconds.</b></p>
                <p>Automatically refreshing this page in <b>{timerSeconds} seconds</b>.</p>
              </div>
            }
          </div>}
          </div>
        </Container>
        <div className='flex-column-reverse'>
          <Footer></Footer>
        </div>
      </StrictMode>
  );
}

export default App;
